import { useState, useEffect } from "react";
import Api from "../Services/Api";
import { useTranslation } from "react-i18next";
import Loading from "./Loading";
import WebhookTests from "./WebhookTests";

function WebhookLogs() {
  const defaultPageSize = 50;
  const { t } = useTranslation();
  const [data, setData] = useState<WebhookLog[] | undefined>([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize] = useState(defaultPageSize);
  const [totalPages, setTotalPages] = useState(defaultPageSize);
  const [isLoadingWebhookLogs, setIsLoadingWebhookLogs] = useState(true);

  type WebhookLog = {
    id: number;
    url: string;
    payload: string;
    response: string;
    statusCode: number;
    statusMessage: string;
    createdAt: string;
  };

  useEffect(() => {
    // Function to fetch data
    const fetchData = async () => {
      try {
        setIsLoadingWebhookLogs(true);
        const response = await new Api().getWebhookLogs(page, pageSize);
        setIsLoadingWebhookLogs(false);
        setData(response.data);
        setTotalItems(response.totalItems);
        setTotalPages(response.totalPages);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Fetch data when the component mounts or when page and pageSize change
    fetchData();
  }, [page, pageSize]);

  return (
    <div>
      <WebhookTests></WebhookTests>
      {isLoadingWebhookLogs ? (
        <Loading />
      ) : (
        <div>
          <div>
            <button onClick={() => setPage(page - 1)} disabled={page === 1}>
              {t("pagination.previous")}
            </button>
            {t("pagination.page")} {page}/{totalPages},{" "}
            {t("pagination.showingResults")} {page * defaultPageSize}-
            {(page + 1) * defaultPageSize}/{totalItems}
            <button
              onClick={() => setPage(page + 1)}
              disabled={page >= totalPages}
            >
              {t("pagination.next")}
            </button>
          </div>
          <table>
            <thead>
              <tr>
                <th>URL</th>
                <th>HTTP Status Code</th>
                <th>Time</th>
                <th>More</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item) => (
                <tr key={item.id}>
                  <td>{item.url}</td>
                  <td>{item.statusCode}</td>
                  <td>{new Date(item.createdAt).toLocaleString()}</td>
                  <td>[More]</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            <button onClick={() => setPage(page - 1)} disabled={page === 1}>
              {t("pagination.previous")}
            </button>
            {t("pagination.page")} {page}/{totalPages},{" "}
            {t("pagination.showingResults")} {page * defaultPageSize}-
            {(page + 1) * defaultPageSize}/{totalItems}
            <button
              onClick={() => setPage(page + 1)}
              disabled={page >= totalPages}
            >
              {t("pagination.next")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default WebhookLogs;
