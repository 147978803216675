import { Fragment, useState, useEffect, useCallback } from "react";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import Loading from "../Components/Loading";
import { useParams } from "react-router-dom";
import SleepDonutChart from "../Components/SleepDonutChart";
import { VivantaParameter } from "../Types/VivantaParameter";
import { SleepChartProps } from "../Types/SleepChartProps";
import Api from "../Services/Api";
import { useTranslation } from "react-i18next";
import EmptyData from "../Components/EmptyData";

const TOTAL_SLEEP_DURATION = "total_sleep_duration";
const SHORT_MINUTES_VALUE = 360;
const LONG_MENUTES_VALUE = 540;

function SleepChart() {
  const { dynamicId } = useParams();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [chartProps, setChartProps] = useState<SleepChartProps>({
    sleepChartDataPoints: [
      { name: t("sleepChart.shortSleep"), value: 0 },
      { name: t("sleepChart.optimalSleep"), value: 0 },
      { name: t("sleepChart.longSleep"), value: 0 },
    ],
    totalSleepDuration: 0,
    meanSleepDuration: 0,
  });

  useEffect(() => {
    window.rudderanalytics.page("Sleep Chart");
  }, []);

  const parseChartData = useCallback(
    (data: VivantaParameter[]) => {
      let shortSleepTotal = 0;
      let optimalSleepTotal = 0;
      let longSleepTotal = 0;

      const orderedData = data.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB.getDate() - dateA.getDate();
      });

      orderedData.forEach((item, index) => {
        if (index >= 15) return;
        if (item.parameter_id !== TOTAL_SLEEP_DURATION) return;

        const totalSleepDuration = item.value;

        if (totalSleepDuration === null || totalSleepDuration === 0) return;

        if (totalSleepDuration < SHORT_MINUTES_VALUE) {
          shortSleepTotal++;
          return;
        }

        if (totalSleepDuration > LONG_MENUTES_VALUE) {
          longSleepTotal++;
          return;
        }

        optimalSleepTotal++;
      });

      return [
        { name: t("sleepChart.lessThan6Hours"), value: shortSleepTotal },
        { name: t("sleepChart.between6And9Hours"), value: optimalSleepTotal },
        { name: t("sleepChart.moreThan9Hours"), value: longSleepTotal },
      ];
    },
    [t]
  );

  const parseTotalSleepDuration = (data: VivantaParameter[]) => {
    let numberOfNonZeroSleeps = 0;
    const totalSleepDurationTotal = data.reduce((acc: number, curr: any) => {
      if (curr.parameter_id === TOTAL_SLEEP_DURATION) {
        acc += curr.value;
        if (curr.value > 0) {
          numberOfNonZeroSleeps++;
        }
      }
      return acc;
    }, 0);

    const meanSleepDuration =
      numberOfNonZeroSleeps === 0
        ? 0
        : +totalSleepDurationTotal / numberOfNonZeroSleeps;
    return {
      totalSleepDurationTotal,
      meanSleepDuration,
    };
  };

  useEffect(() => {
    if (dynamicId !== undefined) {
      setLoading(true);
      const api = new Api();
      api
        .getResource(dynamicId)
        .then(async (response) => {
          setLoading(false);
          if (response !== undefined) {
            const sleepChartDataPoints = parseChartData(response);
            const totalsSleepDurations = parseTotalSleepDuration(response);
            setChartProps({
              totalSleepDuration: totalsSleepDurations.totalSleepDurationTotal,
              meanSleepDuration: totalsSleepDurations.meanSleepDuration,
              sleepChartDataPoints,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [dynamicId, parseChartData]);

  const getBarStyle = (active: boolean) => {
    if (active) {
      return { opacity: 1.0, fontWeight: "bold" };
    }

    return { opacity: 0.5, fontWeight: "thin" };
  };

  return (
    <Fragment>
      <Container className="mt-4">
        {loading ? (
          <Loading />
        ) : chartProps.totalSleepDuration === 0 ? (
          <EmptyData parameter="sleep" />
        ) : (
          <Fragment>
            <h3 className="text-center">{t("sleepChart.sleepScore")}</h3>
            <Row className="mt-4">
              <Col
                lg={{ offset: 4, span: 4 }}
                md={{ offset: 3, span: 6 }}
                sm={{ offset: 2, span: 8 }}
              >
                <ProgressBar>
                  <ProgressBar
                    variant="warning"
                    now={33}
                    key={1}
                    style={{
                      opacity:
                        chartProps.meanSleepDuration < SHORT_MINUTES_VALUE
                          ? 1.0
                          : 0.5,
                    }}
                  />
                  <ProgressBar
                    variant="success"
                    now={33}
                    key={2}
                    style={{
                      opacity:
                        chartProps.meanSleepDuration >= SHORT_MINUTES_VALUE &&
                        chartProps.meanSleepDuration <= LONG_MENUTES_VALUE
                          ? 1.0
                          : 0.5,
                    }}
                  />
                  <ProgressBar
                    variant="warning"
                    now={34}
                    key={3}
                    style={{
                      opacity:
                        chartProps.meanSleepDuration > LONG_MENUTES_VALUE
                          ? 1.0
                          : 0.5,
                    }}
                  />
                </ProgressBar>
                <Row>
                  <Col
                    className="text-center"
                    style={getBarStyle(
                      chartProps.meanSleepDuration < SHORT_MINUTES_VALUE
                    )}
                  >
                    &uarr;
                    <br />
                    Short
                  </Col>
                  <Col
                    className="text-center"
                    style={getBarStyle(
                      chartProps.meanSleepDuration >= SHORT_MINUTES_VALUE &&
                        chartProps.meanSleepDuration <= LONG_MENUTES_VALUE
                    )}
                  >
                    &uarr;
                    <br />
                    Optimal
                  </Col>
                  <Col
                    className="text-center"
                    style={getBarStyle(
                      chartProps.meanSleepDuration > LONG_MENUTES_VALUE
                    )}
                  >
                    &uarr;
                    <br />
                    Long
                  </Col>
                </Row>
              </Col>
            </Row>
            <br />
            <br />
            <h3 className="text-center">{t("sleepChart.sleepDistribution")}</h3>
            <SleepDonutChart
              sleepChartDataPoints={chartProps.sleepChartDataPoints}
              meanSleepDuration={chartProps.meanSleepDuration}
              totalSleepDuration={chartProps.meanSleepDuration}
            />
            <br />
            <br />
            <div className="text-center mb-2" style={{ fontSize: "12px" }}>
              {t("sleepChart.legend1")}
            </div>
            <div className="text-center mb-2" style={{ fontSize: "12px" }}>
              {t("sleepChart.legend2")}
            </div>
            <br />
            <br />
          </Fragment>
        )}
      </Container>
    </Fragment>
  );
}

export default SleepChart;
