import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Area,
  ComposedChart,
} from "recharts";
import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";

export type LineChartType = {
  axis: {
    xLabel: string;
    yLabel: string;
  };
  data: {
    x: string;
    avgHeartRateThisWeek: number;
    avgHeartRateLastWeek: number;
    minRecommendedValue: number;
  }[];
};

type AvgHeartRateLineChartProps = {
  lineChart: LineChartType;
};

function AvgHeartRateLineChart(props: AvgHeartRateLineChartProps) {
  const [graphData, setGraphData] = useState<LineChartType>({
    axis: {
      xLabel: "",
      yLabel: "",
    },
    data: [],
  });

  useEffect(() => {
    setGraphData(props.lineChart);
  }, [props.lineChart]);

  return (
    <Row>
      <Col sm={12}>
        {graphData && graphData.data && graphData.data.length > 0 ? (
          <ResponsiveContainer height={460}>
            <ComposedChart data={graphData["data"]}>
              <Legend verticalAlign="top" height={80} />
              <XAxis dataKey="x" name={graphData["axis"]["xLabel"]} />
              <YAxis name="Y" />
              <Line
                type="monotone"
                dataKey="avgHeartRateThisWeek"
                fill="#3343d6ff"
                stroke="#3343d6ff"
                strokeWidth={2}
                name={"Average Heart Rate this week"}
                fontSize={12}
              />
              <Area
                type="monotone"
                dataKey="avgHeartRateLastWeek"
                fill="#f6956b33"
                stroke="#f6956bff"
                name={"Average Heart Rate last week"}
              />
              <Line
                type="monotone"
                strokeWidth={2}
                dataKey="minRecommendedValue"
                name={"Minimum recommended value"}
                stroke="#ff6666"
              ></Line>
              <Tooltip />
            </ComposedChart>
          </ResponsiveContainer>
        ) : (
          "No data"
        )}
      </Col>
    </Row>
  );
}

export default AvgHeartRateLineChart;
