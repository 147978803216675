import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import error from "../Images/error_icon.png";

const EmptyData = (props: { parameter: string }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="row mt-4">
        <div className="col-10 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
          <div className="row mb-4 mt-4">
            <div className="col-6 offset-3">
              <img src={error} alt="error" className="img-fluid" />
            </div>
          </div>
          <h2 className="text-center mb-3 mt-2">
            {t("emptyData.title", { parameter: props.parameter })}
          </h2>
          <h5 className="text-center">{t("emptyData.legend")}</h5>
        </div>
      </div>
    </Fragment>
  );
};

export default EmptyData;
