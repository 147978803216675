import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loading from "./Components/Loading";
import ActivitiesChart from "./Pages/ActivitiesChart";
import Home from "./Pages/Home";
import Start from "./Pages/Start";
import StepsChart from "./Pages/StepsChart";
import SleepChart from "./Pages/SleepChart";
import AggregatedParametersChart from "./Pages/AggregatedParameterStepsChart";
import WebhookLogs from "./Components/WebhookLogs";
import IntradayHeartRate from "./Pages/IntradayHeartRate";
import CaloriesChart from "./Pages/CaloriesChart";
import AvgHeartRateChart from "./Pages/AvgHeartRateChart";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Loading />} />
        <Route path="/home" element={<Home />} />
        <Route path="/graphs/:dynamicId" element={<StepsChart />} />
        <Route
          path="/graphs/steps-by-week/:dynamicId"
          element={<StepsChart />}
        />
        <Route
          path="/graphs/activities-by-week/:dynamicId"
          element={<ActivitiesChart />}
        />
        <Route
          path="/graphs/sleeps-by-week/:dynamicId"
          element={<SleepChart />}
        />
        <Route
          path="/graphs/calories-by-week/:dynamicId"
          element={<CaloriesChart />}
        />
        <Route
          path="/graphs/avg-heart-rate-by-week/:dynamicId"
          element={<AvgHeartRateChart />}
        />
        <Route
          path="/graphs/intraday-heart-rate/:userId/:brandId/:intradayDate"
          element={<IntradayHeartRate />}
        />
        <Route path="/webhook-logs" element={<WebhookLogs />} />
        <Route
          path="/graphs/sleep-by-week/:dynamicId"
          element={<SleepChart />}
        />
        <Route
          path="/graphs/aggregated-paramters/steps"
          element={<AggregatedParametersChart />}
        />
        <Route path={"/start/:publicKey/:customerId"} element={<Start />} />
        <Route
          path={"/test/activities-chart/:dynamicId"}
          element={<ActivitiesChart />}
        />
        <Route path={"/test/sleep-chart/:dynamicId"} element={<SleepChart />} />
        <Route path="*" element={<h1>404 Not Found</h1>} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
