import { Fragment, useEffect, useState } from "react";
import Navigation from "../Components/Navigation";
import Api from "../Services/Api";
import { Col, Container, Row, Table } from "react-bootstrap";

const baseBrands = {
  1: 0, // Fitbit
  2: 0, // Withings
  3: 0, // Polar
  4: 0, // Garmin
  5: 0, // Google Fit
  6: 0, // Apple Health
  7: 0, // Whoop
  8: 0, // Google Health
};

const Home = () => {
  const [data, setData] = useState(false);
  const [users, setUsers] = useState([]);
  const [info, setInfo] = useState({
    totalUsers: 0,
    connectedUsers: 0,
    brands: baseBrands,
  });

  useEffect(() => {
    window.rudderanalytics.page("Home");
  }, []);

  useEffect(() => {
    if (!data) {
      const api = new Api();
      const users = api.getUsers();
      users
        .then((result) => {
          setUsers(result);
        })
        .catch((ex) => {
          console.log(ex);
        });
      setData(true);
    }
  }, [data]);

  useEffect(() => {
    let connected = 0;
    let brands: any = baseBrands;
    users.forEach((user: any) => {
      if (user.credentials.length > 0) {
        connected++;
        user.credentials.forEach((credential: any) => {
          if (brands[credential.brandId]) {
            brands = {
              ...brands,
              [credential.brandId]: brands[credential.brandId] + 1,
            };
          } else {
            brands = { ...brands, [credential.brandId]: 1 };
          }
        });
      }
    });
    setInfo({
      totalUsers: users.length,
      connectedUsers: connected,
      brands: brands,
    });
  }, [users]);

  return (
    <Fragment>
      <Navigation />
      <Container className="mt-4">
        <Row>
          <Col>
            <h1>Users</h1>
          </Col>
        </Row>
        <Row className="mt-4">
          <h3>Resume</h3>
          <Col md={6} lg={4} sm={8}>
            <Table striped={true}>
              <tbody>
                <tr>
                  <th colSpan={2}>Total users:</th>
                  <td>{info.totalUsers}</td>
                </tr>
                <tr>
                  <th colSpan={2}>Connected users:</th>
                  <td>{info.connectedUsers}</td>
                </tr>
                <tr>
                  <th colSpan={3}>Brands</th>
                </tr>
                <tr>
                  <th></th>
                  <th>Fitbit</th>
                  <td>{info.brands ? info.brands[1] : 0}</td>
                </tr>
                <tr>
                  <th></th>
                  <th>Withings</th>
                  <td>{info.brands ? info.brands[2] : 0}</td>
                </tr>
                <tr>
                  <th></th>
                  <th>Polar</th>
                  <td>{info.brands ? info.brands[3] : 0}</td>
                </tr>
                <tr>
                  <th></th>
                  <th>Garmin</th>
                  <td>{info.brands ? info.brands[4] : 0}</td>
                </tr>
                <tr>
                  <th></th>
                  <th>Apple Health</th>
                  <td>{info.brands ? info.brands[6] : 0}</td>
                </tr>
                <tr>
                  <th></th>
                  <th>Whoop</th>
                  <td>{info.brands ? info.brands[7] : 0}</td>
                </tr>
                <tr>
                  <th></th>
                  <th>Google Health Connect</th>
                  <td>{info.brands ? info.brands[8] : 0}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <hr />
        <Row>
          <h3>Detail</h3>
          <Table bordered={false} striped={true}>
            <thead>
              <tr>
                <th>External ID</th>
                <th>Created at</th>
                <th>Brands</th>
                <th colSpan={3} className="text-center">
                  Graphs
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map((user: any) => {
                const brands: string[] = [];
                user.credentials.forEach((credential: any) => {
                  brands.push(credential.brandId);
                });
                return (
                  <tr key={user.id}>
                    <td>{user.externalId}</td>
                    <td>{user.createdAt}</td>
                    <td>{brands.join(", ")}</td>
                    <td>[Activity]</td>
                    <td>[Sleep]</td>
                    <td>[Active time]</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Home;
