import { Fragment, useState, useEffect, useCallback } from "react";
import { Container } from "react-bootstrap";
import Loading from "../Components/Loading";
import { useParams } from "react-router-dom";
import Api from "../Services/Api";

import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from "recharts";

enum RequestStatus {
  pending,
  requesting,
  error,
  success,
}

const AggregatedParametersChart = () => {
  const { dynamicId } = useParams();

  const [histogram, setHistogram] = useState<any | undefined>(undefined);

  const [status, setStatus] = useState<RequestStatus>(RequestStatus.pending);

  const formatHistogramData = (data: any) => {
    return Object.keys(data).map((key) => ({
      range: key,
      frequency: data[key],
    }));
  };

  const handleData = useCallback(() => {
    setStatus(RequestStatus.requesting);
    const api = new Api();
    api
      .getAggregatedData([1])
      .then(async (response) => {
        setStatus(RequestStatus.success);
        if (response !== undefined) {
          const histogramRawData = response.histogram;
          const histogramData = formatHistogramData(histogramRawData);
          setHistogram(histogramData);
        }
      })
      .catch((error) => {
        setStatus(RequestStatus.error);
      });
  }, []);

  useEffect(() => {
    if (status === RequestStatus.pending) {
      handleData();
    }
  }, [status, dynamicId, handleData]);

  useEffect(() => {
    window.rudderanalytics.page("Steps Chart");
  }, []);

  return (
    <Fragment>
      <Container className="mt-4">
        {status === RequestStatus.requesting ? (
          <Loading />
        ) : histogram === undefined ? (
          <div></div>
        ) : (
          <Fragment>
            <BarChart
              width={600}
              height={400}
              data={histogram}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <YAxis
                label={{
                  value: "steps range",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <XAxis dataKey="range" />
              <Tooltip />
              <Legend />
              <Bar dataKey="frequency" fill="#8884d8" />
            </BarChart>
          </Fragment>
        )}
      </Container>
    </Fragment>
  );
};

export default AggregatedParametersChart;
