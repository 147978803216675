import { Fragment, useState, useEffect, useCallback } from "react";
import { Container } from "react-bootstrap";
import Loading from "../Components/Loading";
import Api from "../Services/Api";
import { useTranslation } from "react-i18next";
import IntradayHeartRateChart from "../Components/IntradayHeartRateChart";
import { useParams } from "react-router-dom";

enum RequestStatus {
  pending,
  requesting,
  error,
  success,
}

const StepsChart = () => {
  const { userId, brandId, intradayDate } = useParams();
  const { t } = useTranslation();
  const [data, setData] = useState<any>(undefined);

  const [status, setStatus] = useState<RequestStatus>(RequestStatus.pending);

  const handleData = useCallback(
    (intradayDate: string) => {
      setStatus(RequestStatus.requesting);
      const api = new Api();
      if (userId === undefined || brandId === undefined) {
        setStatus(RequestStatus.error);
        return;
      }
      api
        .getHeartIntraday(userId, +brandId, intradayDate)
        .then(async (response) => {
          setStatus(RequestStatus.success);
          if (response !== undefined) {
            setData(response);
          }
        })
        .catch((error) => {
          setStatus(RequestStatus.error);
        });
    },
    [brandId, userId]
  );

  useEffect(() => {
    if (intradayDate !== undefined) {
      handleData(intradayDate);
    }
  }, [intradayDate, handleData]);

  useEffect(() => {
    window.rudderanalytics.page("Intraday Heart Rate Chart");
  }, []);

  return (
    <Fragment>
      <Container className="mt-4">
        {status === RequestStatus.requesting ? (
          <Loading />
        ) : data === undefined ? (
          <div></div>
        ) : (
          <Fragment>
            <h1>{t("intradayHeartRate.title")}</h1>
            <IntradayHeartRateChart data={data} />
          </Fragment>
        )}
      </Container>
    </Fragment>
  );
};

export default StepsChart;
